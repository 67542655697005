import React from 'react';
import LogoImage from '../../../img/svg/toro-horizontal-white.svg';
import {
  BottomLinks,
  BottomSection,
  FooterContainer,
  FooterWrapper,
  InfoContainer,
  StyledLink,
  TopSection,
} from './styles';

export default function Footer() {
  return (
    <FooterWrapper>
      <FooterContainer>
        <TopSection>
          <StyledLink href={process.env.NEXT_PUBLIC_API_GO_HOME || ''}>
            <img src={LogoImage} alt="logo" width={220} />
          </StyledLink>
          <InfoContainer>
            <p>Rafael Toro, Academia de Finanças LTDA</p>
            <p>CNPJ: 30.425.807/0001-37</p>
            <p>Rua Dona Laura, 320 – Sala 801 - Moinhos de Vento, Porto Alegre – RS</p>
            <p>
              <a href="mailto:contato@academiarafaeltoro.com.br" className="hover:underline">
                contato@academiarafaeltoro.com.br
              </a>
            </p>
          </InfoContainer>
        </TopSection>

        <BottomSection>
          <BottomLinks>
            <li>
              <a href="https://www.academiarafaeltoro.com.br/termos-de-uso/">Termos de uso</a>
            </li>
            <li>
              <a href="https://www.academiarafaeltoro.com.br/direitos-autorais/">
                Direitos autorais
              </a>
            </li>
            <li>
              <a href="https://www.academiarafaeltoro.com.br/politica-de-privacidade/">
                Política de privacidade
              </a>
            </li>
          </BottomLinks>
        </BottomSection>
      </FooterContainer>
    </FooterWrapper>
  );
}
