import React, { useContext } from 'react';
import { CheckoutContext } from '../../shared/CheckoutProvider';
import { numberFormat } from '../../../../helpers';
import { Container, Label, Value } from './styles';

const ValuesArea = ({ sideCard }) => {
    const { course, cupom, values, installments, method } = useContext(CheckoutContext);
    const showCourseValue = (course.valor_a_vista !== course.valor_curso) || (values.installments > 1 || values.installments === "" || method === 'credit');
    const isCashValue = values.installments === 1;
    const isCreditCard = method === 'credit';
    const isDebitCard = method === 'debit';
    const isPix = method === 'pix';
    const currentInstallment = installments.find((installement) => installement.key === values.installments);
    const isInstallmentPurchase = currentInstallment?.key > 1;

    const paymentLabel = () => {
        if (isDebitCard) {
            return 'Débito';
        } else if (isPix) {
            return 'PIX';
        }

        return;
    }

    const cupomValue = () => {
        if (isCreditCard) {
            return cupom?.percentage ? 
                (course.valor_curso * cupom.percentage) / 100 : 
                values.detailsCupom.value;
        } 
        
        return cupom?.percentage ? 
            (course.valor_a_vista * cupom.percentage) / 100 : 
            values.detailsCupom.cashValue;
    }

    const totalValue = () => {
        if (!currentInstallment) {
            if (cupom?.valor_cupom_a_vista) {
                return cupom.valor_cupom_a_vista;
            } else {
                return course.valor_curso;
            }
        } else if(isCreditCard) {
            if (cupom?.percentage) {
                return course.valor_curso - (course.valor_curso * (cupom.percentage / 100));
            } else if (cupom?.valor_cupom) {
                return cupom.valor_cupom;
            }
            
            return course.valor_curso;
        }

        if (cupom?.percentage) {
            return course.valor_a_vista - (course.valor_a_vista * (cupom.percentage / 100));
        } else if (cupom?.valor_cupom_a_vista) {
            return cupom.valor_cupom_a_vista;
        } 

        return course.valor_a_vista;
    }

    return (
        <Container sideCard={sideCard}>
            {values.type === 'free' ? (
                <>
                    <Label hidden>Total</Label>
                    <Value total free sideCard={sideCard}>
                        R$ {numberFormat(cupom?.percentage ? 
                          course.valor_curso - (course.valor_curso * (cupom?.percentage / 100)) : 
                          cupom?.valor_cupom ? cupom.valor_cupom : course.valor_curso
                        )}
                    </Value>
                </>
            ) : (
                <>
                    {showCourseValue && (
                        <>
                            <Label>Valor</Label>
                            <Value>
                                {isCashValue && !isCreditCard ? (
                                    <del>R$ {numberFormat(course.valor_curso, 2)}</del>
                                ) : (
                                    `R$ ${numberFormat(course.valor_curso, 2)}`
                                )}
                            </Value>
                            {isCashValue && !isCreditCard && (
                                <>
                                    <Label>Valor {paymentLabel()}</Label>
                                    <Value>R$ {numberFormat(course.valor_a_vista, 2)}</Value>
                                </>
                            )}
                            {cupom && (
                                <>
                                    <Label className='cupom-item'>Cupom aplicado: {cupom?.percentage && `${cupom.percentage}%`}</Label>
                                    <br/>
                                    <Value>
                                        {cupom?.percentage ? `- R$ ${numberFormat(cupomValue())}` 
                                        : cupom.id_desconto ? `R$ ${numberFormat(cupomValue())}` 
                                        : '--'}
                                    </Value>
                                </>
                            )}
                            {isInstallmentPurchase && isCreditCard && (
                                <>
                                    <Label>Parcelas</Label>
                                    <Value>{`${currentInstallment.key}x R$ ${numberFormat(currentInstallment.value, 2)}`}</Value>
                                </>
                            )}
                            {!!cupom?.cashback_percentage && (
                                <>
                                    <Label>Cashback {cupom.cashback_percentage}%</Label>
                                    <Value>{`R$ ${numberFormat((course.valor_a_vista * cupom.cashback_percentage) / 100, 2)}`}</Value>
                                </>
                            )}
                            {cupom?.cashback && !cupom.cashback_percentage && (
                                <>
                                    <Label>Cashback</Label>
                                    <Value>R$ {numberFormat(cupom.cashback, 2)}</Value>
                                </>
                            )}
                            <>
                                <Label hidden>Total</Label>
                                <Value total sideCard={sideCard}>R$ {numberFormat(totalValue())}</Value>
                            </>
                        </>
                    )}
                </>
            )}
        </Container>
    );
}

export default ValuesArea;