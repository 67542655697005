import styled, { css } from 'styled-components';

export const Container = styled.dl`
    padding: 0 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #BBBBBB;
    padding: 0;
        display: grid;
        grid-template: auto / 50% 50%;

    ${({ sideCard }) => sideCard && css`
        margin: 30px 0;
        border-top: 2px dotted #CCCCCC;
        border-bottom: 2px dotted #CCCCCC;
        padding: 30px 24px;
        font-size: 18px;
        line-height: 22px;
    `}
`;

export const Label = styled.dt`
    grid-column-start: 1;
    font-weight: 600;

    font-size: 1rem;

    ${({ hidden }) => hidden && css`
        display: none; 
    `}

    @media (max-width: 768px) {
        font-size: 0.72rem
    }

    &.cupom-item{
        text-wrap: nowrap;
        color: #B20000;
    }
`;

export const Value = styled.dd`
    text-align: right;
    grid-column-start: 2;
    font-size: 1rem;

    ${({ total }) => total && css`
        margin-top: 5px;
        grid-column-start: 1;
        grid-column-end: column-end;
        line-height: 31px;
        font-size: 25px;
        color: #000a66;

        ${({ sideCard }) => sideCard && css`
            margin-top: 30px;
            line-height: 33px;
            font-size: 27px;
        `}
    `}

    ${({ free }) => free && css`
        color: #2EC76B;
    `}

    @media (max-width: 768px) {
        font-size: 0.72rem;
    }
`;