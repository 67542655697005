import React, { useContext, useState } from 'react';
import { Row, Col, Form, Checkbox, Spin } from 'antd';
import { LeftOutlined, RightOutlined, WarningOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { CheckoutContext } from '../../../../components/NewCheckout/shared/CheckoutProvider';
import useDocumentSize from '../../../../hooks/useDocumentSize';
import TopCard from '../../../../components/NewCheckout/components/TopCard';
import SideCard from '../../../../components/NewCheckout/components/SideCard';

import '../../../../components/NewCheckout/components/style/style.scss';

import { ICourse } from '../../../../shared/Models/ICurso';
import { numberFormat } from '../../../../helpers';

import Button from '../../../../components/Button';
import Alert from '../../../../components/ModalAlert';
import BoxError from '../../../../components/NewCheckout/components/BoxError';

import {
    WrapperCheckbox,
    Value,
    WrapperButtons,
    Divider,
    TotalDescription,
    Container,
    BoxLoading,
    BoxEmpty,
    CustomLinkButton
} from './styles';
import config from '../../../../config';

type CourseProps = {
    cursos_adicionais: Array<ICourse>;
    is_error: boolean;
};

export default function List() {
    const [validation, setValidation] = useState({
        visible: false,
        message: '',
    });

    const theme = useTheme();
    const size = useDocumentSize();

    const isMobile = size.width <= theme.media.tablet;

    const {
        course: _course,
        userSelectedModules,
        onUserSelectedModule,
        onInstallmentsByModule,
    } = useContext<any>(CheckoutContext);
    const course = _course as CourseProps;
    const total = (userSelectedModules ?? []).reduce(
        (acc: number, module: ICourse) => acc + module.valor_curso,
        0
    );

    function toggleAlert(visible: boolean) {
        setValidation({
            visible,
            message: '',
        });
    }

    function handleModule(module: ICourse) {
        onUserSelectedModule(module, () => {
            setValidation({
                visible: true,
                message: 'Por favor, desmarque algum dos módulos para selecionar outro',
            });
        });
    }

    function checkedModule(module: ICourse) {
        const modules = (userSelectedModules ?? []) as Array<ICourse>;
        return !!modules.find((_module) => _module.id_curso === module.id_curso);
    }

    function handleNext() {
        const isProduction = process.env.NODE_ENV === 'production';

        if (userSelectedModules.length === 0) {
            return setValidation({
                visible: true,
                message: 'Por favor selecione ao menos 1 módulo antes de continuar',
            });
        }

        const indexSelectedModules = userSelectedModules
            .map((module: ICourse) => {
                return (
                    course.cursos_adicionais.findIndex(
                        (_module: ICourse) => _module.id_curso === module.id_curso
                    ) + 1
                );
            })
            ?.sort((a: number, b: number) => a - b)
            ?.join('');

        onInstallmentsByModule(() => {
            const url = `${isProduction ? '/checkout' : ''}/${
                config.CFP_MOD_PREFIX
            }${indexSelectedModules}/`;
            window.location.href = url;
        });
    }

    function handlePrevious() {
        window.history.back();
    }

    return (
        <>
            {!course || course?.is_error ? (
                <Container className="content-l" gutter={isMobile ? [0, 0] : [60, 60]}>
                    <BoxError />
                </Container>
            ) : !course?.cursos_adicionais ? (
                <Container className="content-l" gutter={isMobile ? [0, 0] : [60, 60]}>
                    <BoxLoading>
                        <Spin />
                    </BoxLoading>
                </Container>
            ) : (
                <>
                    <Alert
                        isVisible={validation.visible}
                        setVisible={() => toggleAlert(false)}
                        title={validation.message}
                    />

                    <Container className="content-l" gutter={isMobile ? [0, 0] : [60, 60]}>
                        {size.width <= 1200 && (
                            <TopCard current={1} responseStatus="" showTotal={false} />
                        )}

                        <Col xs={size.width >= 1200 ? '' : 24} span={15}>
                            <Form>
                                <Row>
                                    <Col xs={24}>
                                        <h5 className="title-steps">Selecione até 3 módulos {!isMobile && (<br/>)}que deseja estudar: </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        {course.cursos_adicionais?.map((module) => {
                                            const checked = checkedModule(module);
                                            return (
                                                <div key={module.id_curso}>
                                                    <WrapperCheckbox
                                                        className="checkbox"
                                                        marked={checked}
                                                    >
                                                        <Checkbox
                                                            onChange={(_) => handleModule(module)}
                                                            checked={checked}
                                                        >
                                                            <span className="checkbox-text">
                                                                {module.nome}
                                                            </span>
                                                        </Checkbox>
                                                        <Value>
                                                            R${' '}
                                                            {numberFormat(
                                                                module.valor_curso,
                                                                2,
                                                                ',',
                                                                '.'
                                                            )}
                                                        </Value>
                                                    </WrapperCheckbox>
                                                    <br />
                                                </div>
                                            );
                                        })
                                    }

                                        {!course.cursos_adicionais?.length && (
                                            <BoxEmpty>
                                                <WarningOutlined />
                                                &nbsp; Ops! Nenhum módulo disponível.
                                            </BoxEmpty>
                                        )}
                                    </Col>
                                </Row>

                                <Divider />
                                <TotalDescription>
                                    <CustomLinkButton href={`${config.FULL_CFP_URL}`} rel='noreferrer noopener' target="_self">
                                        Desejo comprar o curso completo
                                        <RightOutlined />
                                    </CustomLinkButton> 
                                    <span className='total-description'>Total: R$ {numberFormat(total, 2, ',', '.')}</span>
                                </TotalDescription>

                                <WrapperButtons>
                                    {isMobile ? (
                                        <>
                                            <Button layout="warning" onClick={handleNext}>
                                                <span className="btn-text">Próximo</span>
                                                <RightOutlined />
                                            </Button>

                                            <Button
                                                layout="outline-primary"
                                                onClick={handlePrevious}
                                            >
                                                <LeftOutlined />
                                                <span className="btn-text">Voltar</span>
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                layout="outline-primary"
                                                onClick={handlePrevious}
                                            >
                                                <LeftOutlined />
                                                <span className="btn-text">Voltar</span>
                                            </Button>

                                            <Button
                                                style={{ marginLeft: 'auto' }}
                                                layout="warning"
                                                onClick={handleNext}
                                            >
                                                <span className="btn-text">Próximo</span>
                                                <RightOutlined />
                                            </Button>
                                        </>
                                    )}
                                </WrapperButtons>
                            </Form>
                        </Col>

                        {size.width > 1200 && (
                            <SideCard current={1} responseStatus="" showTotal={false} />
                        )}
                    </Container>
                </>
            )}
        </>
    );
}
