import React, { useContext } from 'react';
import { Col } from 'antd';
import P from 'prop-types';

import { CheckoutContext } from '../shared/CheckoutProvider';
import config from '../../../config';
import defaultImg from '../../../img/user-default.png';

import './style/sideCard.scss';
import { CuponsArea } from './CuponsArea';
import ValuesArea from './ValuesArea';

const propTypes = {
  current: P.number.isRequired,
  responseStatus: P.string.isRequired,
  showTotal: P.bool,
  paymentPixStatus: P.string
};

const SideCard = ({ current, responseStatus, showTotal = true, paymentPixStatus }) => {
  const { course } = useContext(CheckoutContext);

  return (
    <>
      {course && !course?.is_error && (
        <Col span={8} style={{ marginBottom: '141px' }}>
          <div className="container-card-course">
            <div className={current === 5 ? 'failed-payment' : ''}></div>
            <div style={current === 5 ? { filter: 'grayscale(100%)' } : {}}>
              {course.img_capa ? (
                <img
                  className="main-img-card"
                  src={config.s3Url + course.img_capa}
                  width="100%"
                  alt={course.nome}
                />
              ) : (
                <img
                  className="img-conatiner-card"
                  src={defaultImg}
                  width="100%"
                  alt={course.nome}
                />
              )}
            </div>
            {((current === 4 && responseStatus === 'APPROVED') || paymentPixStatus === "paid") && (
              <div className="container-card-confirmed">Confirmado!</div>
            )}
            <Col span={24} justifyContent="center">
              <div className="course-name">
                <p>{course.curso}</p>
              </div>
            </Col>
            {showTotal && current !== 4 && (paymentPixStatus === "waiting") && <ValuesArea sideCard />}
            {course?.valor_curso < config.MAX_INSTALLMENTS || course?.coupon !== 1 ? null : <CuponsArea />}
          </div>
        </Col>
      )}
    </>
  );
};

SideCard.propTypes = propTypes;
export default SideCard;
