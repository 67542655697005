import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background-color: #0f1333;
  color: #fff;
  padding: 2rem;
`;

export const FooterContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledLink = styled.a`
  cursor: pointer;
  background: transparent;
  width: fit-content;
  height: fit-content;
  border: none;
`;

export const InfoContainer = styled.div`
  p {
    margin-top: 0.5rem;

    &:first-of-type {
      margin-top: 0;
      font-size: 1.125rem;
      font-weight: bold;
      letter-spacing: 0.025em;
    }

    a {
      color: #fff;
    }
  }
`;

export const BottomSection = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const BottomLinks = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 640px) {
    flex-direction: row;
    gap: 1.5rem;
  }

  li {
    font-size: 0.875rem;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
