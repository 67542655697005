import academiaRafaelToroLogo from './img/svg/toro-horizontal-white.svg';
import artConcursosLogo from './img/svg/logo-light.svg';

require('dotenv').config();

const config = {
  API_BASE: process.env.REACT_APP_API_URL,
  PLATFORM_URL: process.env.REACT_APP_PLATFORM_URL,
  s3Url: process.env.REACT_APP_S3_URL,
  servicesUris: {},
  apiKey: '@newCheckout',
  GTM_ID: process.env.REACT_APP_GTM,
  hiddenMenu: [''],
  INSTALLMENT_VALUE: process.env.REACT_APP_INSTALLMENT_VALUE,
  MAX_INSTALLMENTS: process.env.REACT_APP_MAX_INSTALLMENTS,
  CFP_MOD_PREFIX: process.env.REACT_APP_CFP_MOD_PREFIX,
  LINK_CONTACT_URL: process.env.REACT_APP_LINK_CONTACT_URL,
  FULL_CFP_URL: process.env.REACT_APP_FULL_CFP_URL,
  AMBIENTE_GETNET: process.env.REACT_APP_AMBIENTE_GETNET,
  dataByDomain: {
    'default': {
      logo: academiaRafaelToroLogo,
      hasMenu: true,
      url: process.env.REACT_APP_PLATFORM_URL
    },
    'artconcursos.com.br': {
      logo: artConcursosLogo,
      hasMenu: false,
      url: 'https://app.artconcursos.com.br'
    }
  }
};

export default config;
