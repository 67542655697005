import React, { useContext, useEffect, useState } from 'react';

import { CheckoutContext } from '../shared/CheckoutProvider';

import { Button, Col, Row, Input, Spin, Form } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

const { Item } = Form;

export function CuponsArea() {
  const { values, cupom, applyCupom, removeCupom, applyCupomLoading, setChangeValues } =
    useContext(CheckoutContext);
  const [hasUrlCupom, setHasUrlCupom] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryCupom = urlParams.get('cupom');

    if(queryCupom) {
      setChangeValues(queryCupom, 'cupom');
      setHasUrlCupom(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(hasUrlCupom && values.cupom && values.id_turma) {
      setTimeout(() => {
        applyCupom();
        setHasUrlCupom(false);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [hasUrlCupom, values.cupom, values.id_turma]);

  return (
    <Col className="gutter-row" style={{ width: '100%' }}>
      <h5 className="title-form margin-cupons">Cupom de desconto</h5>
      <Item className="input-form" name="cupom">
        <Row className="cupom-container">
          <Col>
            <Input
              className="cupom-form cupons-mobile-input"
              placeholder="Digite seu cupom "
              value={values.cupom}
              onChange={(e) => setChangeValues(e.target.value, 'cupom')}
              disabled={values?.detailsCupom?.valid || hasUrlCupom || applyCupomLoading ||  cupom?.id_desconto}
            />
          </Col>
          <Col>
            {values.detailsCupom?.cashValue || values?.detailsCupom.value || cupom?.id_desconto ? (
              <Button className="btn-button btn-cupom" onClick={removeCupom}>
                Remover
              </Button>
            ) : (
              <Button className="btn-button btn-cupom" onClick={applyCupom}>
                {!applyCupomLoading && !hasUrlCupom ? 'Aplicar' : <Spin indicator={<LoadingOutlined spin />} />}
              </Button>
            )}
          </Col>
        </Row>
      </Item>
    </Col>
  );
}
