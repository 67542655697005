import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import { Footer, Header } from '../../components/NewCheckout';
import CheckoutProvider from '../../components/NewCheckout/shared/CheckoutProvider';
import { getDataByDomain } from '../../utils';

import { Container } from './styles';
import { v4 as uuidv4 } from 'uuid';
// import config from '../../config';

type Props = {
  children: ReactNode
};

function Checkout({ children }: Props) {
  const session_id = uuidv4();
  const { hasMenu } = getDataByDomain();
  const ambiente_getnet = "k8vif92e"; //config.AMBIENTE_GETNET;

  return (
      <CheckoutProvider session_id={session_id}>
          <Helmet>
              <meta charSet="utf-8" />
              <meta name="robots" content="noindex" />
              <title>Finalizar compra - Rafael Toro - Academia de Finanças</title>
              <script
                type="text/javascript"
                src={`https://h.online-metrix.net/fp/tags.js?org_id=${ambiente_getnet}&session_id=${session_id}`}>
              </script>
          </Helmet>

          <Container>
              <Header />
              {children}
              {hasMenu && <Footer />}
          </Container>
      </CheckoutProvider>
  );
}

export default Checkout;
