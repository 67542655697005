import React, { useContext } from 'react';
import P from 'prop-types';

import './style/topCard.scss';
import config from '../../../config';
import { Col, Row, Image} from 'antd';
import { CheckoutContext } from '../shared/CheckoutProvider';
import defaultImg from '../../../img/user-default.png';

import ValuesArea from './ValuesArea';
import { CuponsArea } from './CuponsArea';

const propTypes = {
    current: P.number.isRequired,
    responseStatus: P.string.isRequired,
    showTotal: P.bool,
    paymentPixStatus: P.string
};

const TopCard = ({ current, responseStatus, showTotal = true, paymentPixStatus }) => {
    const { course } = useContext(CheckoutContext);

    return (
        <>
            {
                (!course || course?.id_error) ? (<></>) : (
                    <Row className={`card-price-xs-lg ${['DENIED', 'ERROR'].includes(responseStatus) ? 'card-price-xs-lg-error' : ''}`}>
                        <Col xs={12}>
                            <div className={current === 5 ? "failed-payment-xs-md" : ""}></div>
                            <div className='img-content' style={current === 5 ? { filter: 'grayscale(100%)' } : {}}>
                                {
                                    course.img_capa ? (
                                        <Image className="img-conatiner-card" src={config.s3Url + course.img_capa} />
                                    ) : (
                                        <Image className="img-conatiner-card" src={defaultImg} />
                                    )
                                }
                            </div>
                        </Col>
                        <Col xs={12}>
                            <h2 className="title-card-price-xs-lg">{course.curso}</h2>
                            {showTotal && current !== 4 && (paymentPixStatus === "waiting") && <ValuesArea />}
                        </Col>
                    </Row>
                )
            }
            {course?.valor_curso < config.MAX_INSTALLMENTS || course?.coupon !== 1 ? null : <CuponsArea />}
        </>
    );
};

TopCard.propTypes = propTypes;
export default TopCard;
